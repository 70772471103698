import { Paging } from '../../types/DataResult';
import { FilterParams } from '../../types/GlobalType';
import { Utils } from '../../utils/Utils';

export function buildPagingParams(paging?: Paging): FilterParams {
  const queryParams: FilterParams = {};
  // Limit
  if (!Utils.isNullOrUndefined(paging?.limit)) {
    queryParams['Limit'] = paging.limit.toString();
  }
  // Skip
  if (!Utils.isNullOrUndefined(paging?.skip)) {
    queryParams['Skip'] = paging.skip.toString();
  }

  return queryParams;
}
