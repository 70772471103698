import { Utils } from '../../utils/Utils';
import { Ordering } from '../../types/DataResult';
import { FilterParams } from '../../types/GlobalType';

export function buildSortingParams(ordering: Ordering[]): FilterParams {
  if (Utils.isEmptyArray(ordering)) {
    return {};
  }

  return {
    SortFields: ordering.map((order) => order.field).filter(Boolean),
  };
}
